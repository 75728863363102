/* Used for selectively applying a theme color for Omni Home changes */
.omni {
	/* colors */
	.omni-theme-grey {
		color: var(--theme-grey);
	}

	.omni-theme-grey-dark {
		color: var(--theme-grey-dark);
	}

	.omni-theme-grey-darker {
		color: var(--theme-grey-darker);
	}

	.omni-theme-primary {
		color: var(--theme-primary);
	}

	.omni-theme-primary-light {
		color: var(--theme-primary-light);
	}

	.omni-theme-primary-dark {
		color: var(--theme-primary-dark);
	}

	.omni-theme-secondary {
		color: var(--theme-secondary);
	}

	.omni-theme-accent {
		color: var(--theme-accent);
	}

	.omni-theme-emphasis {
		color: var(--theme-emphasis);
	}

	/* background colors */
	.omni-bg-theme-primary-dark {
		background-color: var(--theme-primary-dark);
	}

	.omni-bg-theme-primary-lighter {
		background-color: var(--theme-primary-lighter);
	}

	.omni-bg-theme-accent {
		background-color: var(--theme-accent);
	}

	.omni-bg-theme-accent-darker {
		background-color: var(--theme-accent-darker);
	}

	.omni-bg-theme-grey-lighter {
		background-color: var(--theme-grey-lighter);
	}
}
