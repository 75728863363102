/* TODO: EFDC-16856: reduce use of custom css for header */

.omni {
	.omniHomeUniversalLocationCity {
		font-weight: 400;
	}

	.omniHomeUniversalLocation {
		line-height: 1.5;
		font-size: 0.75rem;
	}

	.omniHomeNavMenuItem {
		grid-area: menu;
	}

	.omniHomeAccountItem {
		grid-area: account;
	}

	.omniHomeProjectsItem {
		grid-area: projects;
	}

	.omniHomeCallItem {
		grid-area: call;
	}

	.omniHomeCartItem {
		grid-area: cart;
	}

	.omniHomeCartLabel {
		padding-top: 2px;
	}

	.omniHomeMobileNavItemContent {
		height: 100%;
		display: flex;
		flex-direction: column;
		color: var(--theme-primary);
		text-align: center;
		align-items: center;
	}

	.omniHomeMobileNavItemContent > svg,
	.omniHomeCartIcon > svg {
		height: 0.8rem;
		width: 1.5rem;
	}

	.omniHomeCartIcon {
		height: 1rem;
	}

	.omniHomeMobileNavCartText {
		margin-left: 0;
	}

	.omniHomeHeaderIcon {
		font-size: 0.75rem;
		height: 100%;
	}

	.omniHomeNavHeight100 {
		height: 100%;
	}

	.omniHomeSearchBar {
		position: relative;
	}

	.omni-input-group {
		max-height: 44px;
	}

	.omni-input-group > .input,
	.omni-input-group > .buttonprimary.input {
		padding-top: 0.5rem;
		padding-bottom: 0.5rem;
	}

	.omniHomeProBadge {
		color: var(--theme-primary);
	}

	.omniHome-dn {
		display: none;
	}

	.omnniHome-shopAllDepartments-bg {
		background-color: var(--theme-primary-darker);
	}

	.omniHome-navBar-bg {
		background-color: var(--theme-primary-dark);
	}

	.omniHomeNavbarAccentBorder {
		border-color: var(--theme-accent);
	}

	.omniHomeNotificationBadge {
		right: -0.5rem;
		padding-top: 0.125rem;
		padding-bottom: 0.125rem;
	}

	.omniPrimaryHover:hover {
		color: var(--theme-primary);
	}

	.omniPrimaryHover:hover > svg {
		color: var(--theme-primary);
	}

	.omniHomeGlobalTop {
		& a {
			color: var(--theme-accent-dark);
			text-decoration: none;
			font-weight: normal;
			line-height: 1;
		}

		& a:hover {
			text-decoration: underline;
		}
	}

	.omniSearchBarNoMaxWidth {
		max-width: none;
	}

	.omniCartQuantityPosition {
		top: -0.1rem;
		left: 0.07rem;
	}

	.omniMobileAccountBadge {
		top: 0.1rem;
		right: 0.1rem;
	}

	.omniCartIcon {
		position: relative;
		top: -0.34em;
	}

	.omniMobileCartDisplay {
		display: block;
		flex-direction: unset;
		justify-content: unset;
	}

	.omniCartScale {
		transform: scale(0.75);
	}

	.omniMobileAccountPaddingBottom {
		padding-bottom: 0.25rem;
	}

	.omniHomeMobileCancel {
		border-width: 0;
		background-color: white;
	}
}

@media (--breakpoint-not-small) {
	.omni {
		.omniHomeGrey-ns {
			color: var(--theme-grey);
		}

		.omniHomeMobileNavItemContent {
			flex-direction: unset;
		}

		.omniHomeUniversalLocation {
			font-size: 0.875rem;
		}

		.omniHomeCartIcon {
			height: 2rem;
		}

		.omniHomeCartIcon:hover {
			color: var(--theme-primary-darker);
		}

		.omniHomeCartIcon > div {
			top: 0;
			left: 0.15em !important;
		}

		.omniHomeCartIcon > svg {
			height: 1em;
			width: 1em;
		}

		.omniHomeSearchBarOpen {
			width: 85%;
			max-width: 85%;
			position: relative;
		}

		.omni-input-group {
			max-height: unset;
		}

		.omniHome-dropdown-notifications {
			display: block;
		}

		.omniProSignupText {
			font-size: 0.875rem;
			font-weight: normal;
			line-height: var(--line-height-copy);
			margin-bottom: 0;
		}

		.omniProSignupLink {
			align-items: center;
			padding-top: 0;
			padding-bottom: 0.1rem;
			margin: 0 1rem;
		}

		.omniProSignupPaddingTop {
			padding-top: 0.05rem;
		}

		.omniAccountDropdownPosition-ns {
			left: -4.5rem;
		}

		.omniAccountGreeting {
			position: absolute;
			bottom: 1.3rem;
			font-size: 0.75rem;
			overflow: hidden;
			white-space: nowrap;
		}

		.omniMenuOpenBorder {
			color: var(--theme-primary-darker);
			border-bottom: 2px solid var(--theme-accent);
		}

		.omniHeaderDropdownButton {
			color: var(--theme-primary-dark);
			border-bottom: 2px solid transparent;
		}

		.omniHeaderDropdownButton:hover {
			color: var(--theme-primary-dark);
			border-bottom: 2px solid var(--theme-accent);
		}

		.omniProjectsPadding {
			padding-left: 0;
			padding-top: 0;
		}

		.omniHomeCartPosition-ns {
			top: -0.5rem;
			left: 1px !important;
		}

		.omniCartIcon {
			position: relative;
			top: -0.5em;
		}

		.omniCartQuantityPosition {
			top: 0;
		}

		.omniCartScale {
			transform: scale(1);
		}
	}
}

@media (--breakpoint-large) {
	.omni {
		.omniHome-dib-l {
			display: inline-block;
		}

		.omniHome-dropdown-notifications {
			display: none;
		}
	}
}
