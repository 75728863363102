.rich-text {
	& a {
		text-decoration: underline;
	}

	& a:hover {
		color: var(--theme-primary-dark);
	}

	& a:visited {
		color: var(--theme-accent-dark);
	}
}
