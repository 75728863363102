.omni {
	.omni-normal {
		font-weight: normal;
	}

	.omni-b {
		font-weight: bold;
	}

	.omni-f3 {
		font-size: 1.5rem;
	}

	.omni-f4 {
		font-size: 1.25rem;
	}

	.omni-f5 {
		font-size: 1rem;
	}

	.omni-f7 {
		font-size: 0.75rem;
	}
}

@media (--breakpoint-not-small) {
	.omni {
		.omni-f3-ns {
			font-size: 1.5rem;
		}

		.omni-f4-ns {
			font-size: 1.25rem;
		}

		.omni-f5-ns {
			font-size: 1rem;
		}

		.omni-f6-ns {
			font-size: 0.875rem;
		}
	}
}
