.omni {
	.link-grey-darker,
	.link-white,
	.link-grey {
		text-decoration: none;
	}

	.link-primary,
	.link-internal,
	.link-grey-darker:hover,
	.link-white:hover,
	.link-grey:hover {
		text-decoration: underline;
	}

	/* must be after other text-decorations to override correctly */
	.link-no-underline,
	.link-no-underline:hover {
		text-decoration: none;
	}

	.link-grey:hover {
		color: var(--theme-grey-dark);
	}

	.link-primary:hover {
		color: var(--theme-primary-dark);
	}

	.link-grey-darker:hover {
		color: var(--theme-black);
	}

	.link-internal:hover {
		color: var(--theme-internal-dark);
	}
}
