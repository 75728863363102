/* TODO: EFDC-16346 Cleanup */

.omni {
	.buttonprimary {
		border: none;
		font-weight: 700;
		border-bottom: 0 !important;
		box-shadow: inset 0 0 0 0.126rem var(--theme-primary-dark);
	}

	.buttonprimary:hover {
		background-color: var(--theme-primary-dark);
		box-shadow: inset 0 0 0 0.126rem var(--theme-primary);
	}

	.buttonprimary:active {
		background-color: var(--theme-primary-darker);
		box-shadow: inset 0 0 0 0.126rem var(--theme-primary-dark);
	}

	.buttonprimaryaccent {
		box-shadow: inset 0 -0.25rem 0 0 var(--theme-secondary), inset 0 0 0 0.126rem var(--theme-primary-dark);
	}

	.buttonprimaryaccent:hover {
		background-color: var(--theme-primary-dark);
		box-shadow: inset 0 -0.25rem 0 0 var(--theme-secondary), inset 0 0 0 0.126rem var(--theme-primary);
	}

	.buttonprimaryaccent:active {
		background-color: var(--theme-primary-darker);
		box-shadow: inset 0 -0.126rem 0 0 var(--theme-secondary), inset 0 0 0 0.126rem var(--theme-primary-dark);
	}

	.buttonprimary.input,
	.buttonsecondary.input,
	.buttoninternal.input,
	.buttoninternalsecondary.input {
		padding-top: 13px;
		padding-bottom: 13px;
	}

	.buttonprimary.input-small,
	.buttonsecondary.input-small,
	.buttoninternal.input-small,
	.buttoninternalsecondary.input-small {
		padding-top: 7.5px;
		padding-bottom: 7.5px;
	}

	.buttonprimary.input-large,
	.buttonsecondary.input-large,
	.buttoninternal.input-large,
	.buttoninternalsecondary.input-large {
		padding-top: 18.5px;
		padding-bottom: 18.5px;
	}

	.buttonprimary:disabled {
		border: none;
		background-color: var(--theme-grey);
		opacity: 0.5;
		font-weight: 700;
		box-shadow: inset 0 0 0 0.126rem var(--theme-grey-dark);
	}

	.buttonprimaryaccent:disabled {
		border: none;
		background-color: var(--theme-grey);
		opacity: 0.5;
		font-weight: 700;
		box-shadow: inset 0 -0.25rem 0 0 var(--theme-grey-darker), inset 0 0 0 -0.25rem var(--theme-grey-dark);
	}

	.buttonprimaryaccent.iconbutton {
		box-shadow: inset 0 -0.15rem 0 0 var(--theme-secondary), inset 0 0.063rem 0 0 var(--theme-primary-dark),
			inset 0.063rem 0 0 0 var(--theme-primary-dark), inset -0.063rem 0 0 0 var(--theme-primary-dark);
	}

	.buttonprimaryaccent.iconbutton:active {
		box-shadow: inset 0 -0.063rem 0 0 var(--theme-secondary), inset 0 0.063rem 0 0 var(--theme-primary-dark),
			inset 0.063rem 0 0 0 var(--theme-primary-dark), inset -0.063rem 0 0 0 var(--theme-primary-dark);
	}

	.buttonprimaryaccent.iconbutton:disabled {
		box-shadow: inset 0 -0.15rem 0 0 var(--theme-grey-darker), inset 0 0.063rem 0 0 var(--theme-grey-dark),
			inset 0.063rem 0 0 0 var(--theme-grey-dark), inset -0.063rem 0 0 0 var(--theme-grey-dark);
	}

	.buttonprimaryaccent.iconbutton:active:disabled {
		box-shadow: inset 0 -0.063rem 0 0 var(--theme-grey-darker), inset 0 0.063rem 0 0 var(--theme-grey-dark),
			inset 0.063rem 0 0 0 var(--theme-grey-dark), inset -0.063rem 0 0 0 var(--theme-grey-dark);
	}

	.buttonprimaryaccent:disabled:active {
		box-shadow: inset 0 -0.25rem 0 0 var(--grey-darker), inset 0 0 0 -0.25rem var(--theme-grey-dark);
	}

	.buttonsecondary {
		color: var(--theme-primary-dark);
		border: none;
		box-shadow: inset 0 0 0 0.126rem var(--theme-primary-light);
		font-weight: 700;
	}

	.buttonsecondary:hover {
		box-shadow: inset 0 0 0 0.126rem var(--theme-primary);
	}

	.buttonsecondary:disabled {
		opacity: 0.5;
		color: var(--theme-grey);
		box-shadow: inset 0 0 0 0.126rem var(--theme-grey-light);
	}

	.buttonsecondary:active {
		color: var(--theme-primary-darker);
		box-shadow: inset 0 0 0 0.126rem var(--theme-primary-dark);
	}

	.buttoninternalsecondary {
		font-weight: 700;
		border: none;
		box-shadow: inset 0 0 0 0.126rem var(--theme-internal-light);
	}

	.buttoninternalsecondary:hover {
		box-shadow: inset 0 0 0 0.126rem var(--theme-internal);
	}

	.buttoninternalsecondary:active {
		box-shadow: inset 0 0 0 0.126rem var(--theme-internal-dark);
	}

	.buttoninternalsecondary:disabled {
		color: var(--theme-grey);
		opacity: 0.5;
		box-shadow: inset 0 0 0 0.126rem var(--theme-grey-light);
	}

	.buttondanger {
		border-width: 2px;
		font-weight: 700;
	}

	.buttoninternal {
		border: none;
		font-weight: 700;
		border-bottom: 0 !important;
		box-shadow: inset 0 0 0 0.126rem var(--theme-internal-dark);
	}

	.buttoninternal:hover {
		background-color: var(--theme-internal-dark);
		box-shadow: inset 0 0 0 0.126rem var(--theme-internal);
	}

	.buttoninternal:disabled {
		background-color: var(--theme-grey);
		box-shadow: inset 0 0 0 0.126rem var(--theme-grey-dark);
	}

	.buttoninternal:active:not(:disabled),
	.buttoninternal:hover:active:not(:disabled) {
		background-color: var(--theme-internal-darker);
		box-shadow: inset 0 0 0 0.126rem var(--theme-internal-dark);
	}
}
