.omni {
	.text-button-underline {
		text-decoration: underline;
	}

	.text-button-primary:hover {
		color: var(--theme-primary-dark);
	}

	.text-button-grey:hover {
		text-decoration: underline;
		color: var(--theme-grey-darker);
	}

	.text-button-grey-darker:hover {
		color: var(--theme-black);
	}

	.text-button-black {
		color: var(--theme-grey-darker);
	}

	.text-button-black:hover {
		text-decoration: underline;
		color: var(--theme-black);
	}

	.text-button-white:hover {
		text-decoration: underline;
		color: var(--theme-grey-lighter);
	}

	.text-button-internal:hover {
		color: var(--theme-internal-dark);
	}
}
